.listContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Ensure content does not overflow */
  width: 95%; /* Add width constraint */
  box-sizing: border-box; /* Include padding in width calculation */
}

.list {
  list-style: none;
  padding: 0;
  margin: 0; /* Remove default margin */
}

.listItem {
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px; /* Add space between name and time */
  flex-wrap: wrap; /* Allow content to wrap on small screens */
}

.listItem:last-child {
  border-bottom: none;
}

.listItem a {
  text-decoration: none;
  color: #2ecc71;
  font-weight: bold;
  transition: color 0.3s;
}

.listItem a:hover {
  color: #27ae60;
}

.listItem span {
  color: #555;
  white-space: nowrap; /* Prevent cooking time from breaking */
}
