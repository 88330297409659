.tableContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table td {
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
}

.table tr:last-child td {
  border-bottom: none;
}

.table td:first-child {
  font-weight: bold;
  color: #333;
}

.table td:last-child {
  color: #555;
}

.table tr:nth-child(even) {
  background: #f9f9f9;
}

/* Match link colors with RecipeList */
.table a {
  text-decoration: none;
  color: #2ecc71;
  font-weight: bold;
  transition: color 0.3s;
}

.table a:hover {
  color: #27ae60;
}
